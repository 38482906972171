import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Processing from '../processing';
import SignIn from './SignIn'
import SelectCard from './SelectCard';
import SubscriptionComplete  from './SubscriptionComplete';
import Box from '@material-ui/core/Box';
import HorizontalLabelPositionBelowStepper from './Steps'
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';



import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingTop: '50px',
        paddingBottom: '100px',
        flexGrow: 1,
        backgroundColor: '#fff',
    },
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    card:{
      fontWeight: '600',
    },
    smH1Title: {
      textAlign: "left",
      color: 'rgba(35, 35, 51, 1)',
      letterSpacing: '0.01em',
      fontWeight: '600',
      paddingTop: "10px",
      marginBlockEnd: 0,
    },
    smH2Title: {
      color: 'rgba(43, 59, 76, 0.4)',
      textAlign: 'left',
      fontSize: "1em",
      textTransform: "uppercase"  
    
    },
    smDescription:{
      color: 'rgba(0,0,0,0.6)',
      fontWeight: 'normal',
      lineHeight: '1.8em',
    },
    smSection:{
      color: '#2b3b4c',
      fontWeight: 'normal',
      lineHeight: '1.6em',
    },    
    smDetail:{
      color: 'rgba(43, 59, 76, 1)',
      lineHeight: '1.6em',
      fontWeight: 'normal',
      '& span':{
        fontWeight: 'normal',
        color: '#001E28'
      }
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    },
    what:{
      color: 'rgba(43, 59, 76, 0.2)',
      textAlign: 'center',
      fontSize: "1.5em",
      textTransform: "uppercase"
    },     
    grey:{
      borderRight: '1px solid rgba(0,0,0,0.005);',
    }     

});



class SubscriptionManagerManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      signed_in: window.logged_in,
      step: window.step,
      error: false,
      error_str: '',
      delete_card: false,
      security: {name: window.security_name, token: window.security_token},
      loading: false,
      delete_focus: {},
      locked: false,
      reload: false,
      payment_methods: window.payment_methods,
    };

    this.currentSend = 0;
    this.onSignedIn  = this.onSignedIn.bind(this);
    this.onComplete  = this.onComplete.bind(this);

  }

  onSignedIn( v, p )
  {
    this.setState({'signed_in': v, 'step': 1, 'payment_methods': p });
  }
  onComplete( v )
  {
    this.setState({'step': 2 });
  }


  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (

            <React.Fragment>
                <div className={classes.root}>
                    {window.logo.length > 0 && (
                      <Grid container direction="row" justify="center" alignContent="flex-start" alignItems="stretch" spacing={2} >
                          <Grid item>
                              <img src={window.logo} alt="thank you" style={{'max-height': '100px'}}/>
                          </Grid>
                      </Grid>
                    )}

                    <Grid container direction="row" justify="center" alignContent="flex-start" alignItems="stretch" spacing={2} >
                      <React.Fragment>
                        <Grid item xs={12}>
                          <HorizontalLabelPositionBelowStepper  key="step-001" step={this.state.step}/>              
                        </Grid>
                        <Grid item xs={12} className={classes.what}>
                            subscription
                        </Grid>
                        {this.state.step < 2 && (
                            <React.Fragment>
                                <Grid item xs={12}  md={7} className={classes.grey}>
                                    <Container component="main" maxWidth="sm">
                                        <CssBaseline />
                                        <Grid container direction="row" justify="center" alignContent="flex-start" alignItems="flex-start" spacing={3} >
                                            <Grid item xs={12}>
                                                <h1 className={classes.smH1Title}>{window.title}</h1> 
                                            </Grid>

                                            <Grid item xs={12}>
                                                <h2 className={classes.smH2Title}>Subscription overview</h2> 
                                            </Grid>
                                            <Grid item xs={4}>
                                            <span className={classes.smSection}>Subscription type: </span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                            <div className={classes.smDetail}><span>{window.subscription_type}</span></div>
                                            </Grid> 
                                            <Grid item xs={4}>
                                            <span className={classes.smSection}>Initial payment:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                            <span className={classes.smDetail}><span>{window.currency}{parseFloat(window.initial_payment) > 0 ? parseFloat(window.initial_payment).toFixed(2):parseFloat(window.payment).toFixed(2)}</span></span>
                                            </Grid> 
                                            <Grid item xs={4}>
                                            <span className={classes.smSection}>Following payments:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                            <div className={classes.smDetail}><span>{window?.currency}{window?.payment}</span> every <span>{window?.term} {window?.term_type}</span> thereafter</div>
                                            </Grid>                               
                                            <Grid item xs={4}>
                                            <span className={classes.smSection}>Minimum term:</span>
                                            </Grid>   
                                            <Grid item xs={8}>
                                              <div className={classes.smDetail}>
                                                {window.min_term > 0 && (
                                                  <div className={classes.smDetail}>There is a minimum term of <span>{window.min_term}</span> days.</div> 
                                                )}
                                                {window.min_term === 0 && (
                                                  <div className={classes.smDetail}>There is no minimum term associated to this subscription. You can cancel anytime.</div> 
                                                )}                                   
                                              </div>
                                            </Grid> 
                                          </Grid>                          
                                      </Container>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                  {this.state.signed_in === false && (
                                    <SignIn callback={this.onSignedIn} security={{'name': window.security_name, 'token': window.security_token}} label="Sign in to get started"/>
                                  )}
                                  {this.state.signed_in === true && this.state.step === 1 && (
                                    <SelectCard callback={this.onComplete} security={{'name': window.security_name, 'token': window.security_token}} payment_methods={this.state.payment_methods}/>
                                  )}  
                                                    
                                </Grid>
                            </React.Fragment>
                        )}
                        {this.state.signed_in === true && this.state.step === 2 && (
                            <SubscriptionComplete security={{'name': window.security_name, 'token': window.security_token}} provider={window.provider}/>
                        )}
                        <Grid item xs={12}>
                          <Box mt={2}>
                          </Box>                
                        </Grid>                  
                      </React.Fragment>

                </Grid>

              {this.state.locked === true && (
                <div className="overlay">
                    <Processing />  
                </div> 
              )}


            </div> 


            </React.Fragment>

    );
  }
}

export default withStyles(styles)(SubscriptionManagerManager);
