import React from 'react';
import {Container} from '@material-ui/core';
import './App.css';
import {
  INTERFACE_TYPE_CUSTOMER_PANEL_CREDIT_CARD,
  INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION_MANAGER,
  INTERFACE_TYPE_CUSTOMER_PANEL_INVOICES_MANAGER,
  INTERFACE_TYPE_CUSTOMER_PANEL_ACCOUNT_SUBSCRIPTIONS_MANAGER,
  INTERFACE_TYPE_CUSTOMER_PANEL_INVOICE,
  INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION,
  INTERFACE_TYPE_CUSTOMER_PANEL_VIRTUAL_CLASS_ACCESS,
  INTERFACE_TYPE_CUSTOMER_PANEL_CLASS_MANAGER
} from './types';
import StripeCreditCardManager from './stripe-credit-card/StripeCreditCardManager';
import SubscriptionManager from './subscriptions/SubscriptionManager';
import InvoiceManager from './account-invoices/InvoiceManager';
import SubscriptionsManager from './account-subscriptions/SubscriptionsManager';
import Invoice from './account-invoices/Invoice'
import Subscription from './account-subscriptions/Subscription';
import VirtualClassManager from './virtual/VirtualClassManager';
import ClassManager from './classmanager/ClassManager';

function App() {
  return (
    <React.Fragment>
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_CREDIT_CARD && (
          <div className="App grey">
            <Container maxWidth="lg">
                <StripeCreditCardManager />
            </Container>
          </div>
          )}  
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION_MANAGER && (
          <div className="App">
            <Container maxWidth="lg">
              <SubscriptionManager />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_INVOICES_MANAGER && (
          <div className="App">
            <Container maxWidth="lg">
              <InvoiceManager />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_ACCOUNT_SUBSCRIPTIONS_MANAGER && (
          <div className="App">
            <Container maxWidth="lg">
              <SubscriptionsManager />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_INVOICE && (
          <div className="App">
            <Container maxWidth="lg">
              <Invoice />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION && (
          <div className="App">
            <Container maxWidth="lg">
              <Subscription />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_VIRTUAL_CLASS_ACCESS && (
          <div className="App rvNS">
            <Container maxWidth="lg">
              <VirtualClassManager />
            </Container>
          </div>
        )}
        {window.interface_select === INTERFACE_TYPE_CUSTOMER_PANEL_CLASS_MANAGER && (
          <div className="App rvNS">
            <Container maxWidth="lg">
              <ClassManager />
            </Container>
          </div>
        )}        

    </React.Fragment>              
  );
}

export default App;
