import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Loading from '../loading';
import uuidv1 from "uuid";






import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    textCenter:{
      textAlign: 'center'
    },        
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    },      

});




class VirtualClassManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      classes: [],
      loading: true,
      security: {name: window.security_name, token: window.security_token},
    };

  }

    componentDidMount(){
      var chain = '&date=' + moment().subtract(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
      var _this = this;
      fetch("/account/ajax-get-virtual-classes", {
          method: "POST",
          body: this.state.security.name + '=' + this.state.security.token + chain,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest'
          }
      })
      .then(res => {
          return res.json();
      })
      .then(response => {
          //console.log(data);
          if(response.status === 'OK')
          {
            _this.setState({'classes': response.data, 'loading': false});
          }
          else
          {
            _this.setState({'classes': [], 'loading': false});
          }
      }).catch(function (err) {
        _this.setState({'classes': [], 'loading': false});
      });      
    }



  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className="appBar">
            <Toolbar>
              <Typography variant="h2" className={classes.title}>
                  Live streamed classes
              </Typography>
            </Toolbar>
        </AppBar>


        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          Date
                        </TableCell>  
                        <TableCell className={classes.cell}>
                          ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Event Name
                        </TableCell>
                        <TableCell className={classes.cellCenter}>
                          Access detail
                        </TableCell>                                                                                                 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.loading === false && this.state.classes.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className={classes.empty}>No live streamed classes.</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.loading === false && this.state.classes.map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          {moment(el.datetime).format("LLL")}
                        </TableCell>  
                        <TableCell>
                          {el.id}
                        </TableCell>
                        <TableCell>
                          {el.name}
                        </TableCell>
                        <TableCell className={classes.textCenter}>
                        <a href={el.access} target="_blank" class="extLink" rel="noopener noreferrer">{el.access}</a>
                        </TableCell>                                                                                
                      </TableRow>
                    ))}
                    {this.state.loading === true && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}                    
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </div>


      </div> 

    );
  }
}

export default withStyles(styles)(VirtualClassManager);
