export const INTERFACE_TYPE_CUSTOMER_PANEL_CREDIT_CARD = 'INTERFACE_TYPE_CUSTOMER_PANEL_CREDIT_CARD';
export const INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION_MANAGER = 'INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION_MANAGER';
export const INTERFACE_TYPE_CUSTOMER_PANEL_INVOICES_MANAGER  =  'INTERFACE_TYPE_CUSTOMER_PANEL_INVOICES_MANAGER';
export const INTERFACE_TYPE_CUSTOMER_PANEL_ACCOUNT_SUBSCRIPTIONS_MANAGER  =  'INTERFACE_TYPE_CUSTOMER_PANEL_ACCOUNT_SUBSCRIPTIONS_MANAGER';
export const INTERFACE_TYPE_CUSTOMER_PANEL_INVOICE  =  'INTERFACE_TYPE_CUSTOMER_PANEL_INVOICE';
export const INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION  =  'INTERFACE_TYPE_CUSTOMER_PANEL_SUBSCRIPTION';
export const INTERFACE_TYPE_CUSTOMER_PANEL_VIRTUAL_CLASS_ACCESS = 'INTERFACE_TYPE_CUSTOMER_PANEL_VIRTUAL_CLASS_ACCESS';
export const INTERFACE_TYPE_CUSTOMER_PANEL_CLASS_MANAGER = 'INTERFACE_TYPE_CUSTOMER_PANEL_CLASS_MANAGER';





export const INVOICE_PAYMENT_STATUS_UNPAID  =  '0';
export const INVOICE_PAYMENT_STATUS_PAID  =  '1';
export const INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING  =  '2';
export const INVOICE_PAYMENT_STATUS_PAYMENT_ACTION  =  '3';
export const INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE  =  '4';
export const INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM  =  '5';
export const INVOICE_PAYMENT_STATUS_PAYMENT_ERROR  =  '-1';

export const INVOICE_PARENT_TYPE_SUBSCRIPTION  =  '1';





export const SUBSCRIPTION_MANDATE_STATUS_ERROR = '-1';
export const SUBSCRIPTION_MANDATE_STATUS_MANUAL = '0';
export const SUBSCRIPTION_MANDATE_STATUS_ACTIVE = '1';
export const SUBSCRIPTION_MANDATE_STATUS_PAUSED = '2';
export const SUBSCRIPTION_MANDATE_STATUS_DELETED = '3';
export const SUBSCRIPTION_MANDATE_STATUS_SUSPENDED = '4';