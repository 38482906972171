import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Loading from '../loading';
import uuidv1 from "uuid";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import CoreDialog from '../dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TableFooter } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    textCenter:{
      textAlign: 'center'
    },        
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    },  
    fabDelete:{
      backgroundColor: '#b80000',
      color: '#fff',
      '&:hover':{
          backgroundColor: '#940000',
      },
      '&:disabled':{
        color: '#fff',
        backgroundColor: grey[300],
        cursor: 'not-allowed',
      }
    }     
});

class DeleteButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('DeleteButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      const s      = this.state.data?.state || false;
      return (
        <React.Fragment>
        {
          s === true &&
          <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Pass" onClick={this._handleClick}>
              <DeleteForeverIcon className={classes.fabIcon}/>
          </IconButton>  
        }
        {
          s === false &&
          <IconButton name="delete" disabled={true} className={classes.fabDelete} aria-label="Delete Pass" onClick={this._handleClick}>
              <DeleteForeverIcon className={classes.fabIcon}/>
          </IconButton>  
        } 
        </React.Fragment>         
      )
  }
}



class ClassManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      classes: window.classes || [],
      loading: true,
      security: {name: window.security_name, token: window.security_token},
      focus: false,
      warn: false,
      loading: false,
      error: false,
    };
    this.onRefresh = this.onRefresh.bind(this);
    this.onCancelClass = this.onCancelClass.bind(this);
    this.onCancelCancel = this.onCancelCancel.bind(this);
    this.onCompleteCancel = this.onCompleteCancel.bind(this);


  }

  onRefresh(){
    var chain = '&date=' + moment().format('YYYY-MM-DD HH:mm:ss');
    var _this = this;
    fetch("/account/ajax-get-all-classes", {
        method: "POST",
        body: this.state.security.name + '=' + this.state.security.token + chain,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
    })
    .then(res => {
        return res.json();
    })
    .then(response => {
        //console.log(data);
        if(response.status === 'OK')
        {
          _this.setState({'classes': response.data, 'loading': false});
        }
        else
        {
          _this.setState({'classes': [], 'loading': false});
        }
    }).catch(function (err) {
      _this.setState({'loading': false});

      //_this.setState({'classes': [], 'loading': false});
    });    
  }


  componentDidMount(){
    this.onRefresh();
  }


  onCancelClass( what ) {
      this.setState({'focus': what, warn: true});
  }
  onCancelCancel( what ) {
    this.setState({'focus': false, 'warn': false, 'loading': false});
  } 

  onCompleteCancel( ){
    var chain = '&id=' + this.state.focus?.id || -1;
    chain = chain + '&did=' + this.state.focus?.did || -1;
    chain = chain + '&txid=' + this.state.focus?.tx_id || -1;
    chain = chain + '&stxid=' + this.state.focus?.stx_id || -1;
    var _this = this;
    this.setState({'loading': true, 'error': false}, function(){
        fetch("/account/ajax-cancel-class", {
            method: "POST",
            body: _this.state.security.name + '=' + _this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
              _this.setState({'loading': false, 'focus': false, 'warn': false}, function(){
                _this.onRefresh();                
              });
            }
            else
            {
              _this.setState({'loading': false, 'focus': false, 'warn': false, 'error': response.reason});
            }
        }).catch(function (err) {
          _this.setState({'loading': false, 'focus': false, 'warn': false, 'error': 'Network problem. Please refesh this page and try again.'});
        });
    });
    
  }

  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className="appBar">
            <Toolbar>
              <Typography variant="h2" className={classes.title}>
                  Class manager
              </Typography>
            </Toolbar>
        </AppBar>


        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>  
                        <TableCell className={classes.cell}>
                          ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Event Name
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Date/Time
                        </TableCell>
                        <TableCell className={classes.cellCenter}>
                          Cancel
                        </TableCell>                                                                                                 
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.loading === false && this.state.classes.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className={classes.empty}>No classes.</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.loading === false && this.state.classes.map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          {el.tx_id}-{el.stx_id}
                        </TableCell>
                        <TableCell>
                          {el.name}
                        </TableCell>
                        <TableCell>
                          {moment(el.datetime).format("LLL")}
                        </TableCell>  

                        <TableCell className={classes.textCenter}>
                          <DeleteButton data={el} classes={classes} callback={this.onCancelClass}/>
                        </TableCell>                                                                                
                      </TableRow>
                    ))}
                    {this.state.loading === true && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}                    
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={4}>
                          {
                            this.state.error &&
                            <Alert severity="error"  onClose={() => { this.setState({'error': false})}}>
                              Error {this.state.error}
                            </Alert>
                          }
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </div>
        {
          this.state.warn === true && this.state.loading === false && <CoreDialog title="Cancel class" body={"<p style=\"text-align:justify;\">Are you sure that you want to cancel this class. If you continue, you will be removed.</p>" }  state={true} danger={true} onCancel={this.onCancelCancel} actions={[{label:"Yes, cancel class", variant:"outlined",  action:this.onCompleteCancel, color: 'default'}]}  />          
        }
      </div> 


    );
  }
}

export default withStyles(styles)(ClassManager);
