import React, { Component } from 'react';
import { useEffect, useState } from "react";

import { useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements
} from "@stripe/react-stripe-js";

import Hidden from '@material-ui/core/Hidden';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {loadStripe} from '@stripe/stripe-js';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// use material theme 
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);






function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}


const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};



const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  divider:{
    width:'8.333333%',
  },
  right:{
    float: 'right',
  },
  consent:{
    alignItems:'flex-start',
    '& .MuiFormControlLabel-label':{
      marginTop: '8px',
    }
  },
  country:{
    marginBottom: '10px'
  },
  processing:{
    minHeight: '300px',
    display: 'block',
    position: 'absolute',
    zIndex: '999',
    top: '50%',
    left: '50%'
  }
});

const CardForm = (props) => {
  const stripe =  useStripe();
  const elements = useElements();
  const options = useOptions();
  const classes = props.classes;
  const lock = props.lock;
  const onOK = props.onOK;
  const security_name = window.security_name;
  const security_token = window.security_token;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [valid, setValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [expiryValid, setExpiryValid] = useState(false);
  const [cvcValid, setCVCValid] = useState(false);
  const [town, setTown] = useState('');
  const [zip, setZip] = useState('');
  const [state, setState] = useState(false);
  const [country, setCountry] = useState('GB');
  const [error, setError] = useState('');
  const [autofocus, setAutoFocus] = useState(true);
  const lab = "I authorise " + props.company + " to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of their agreement with you.";

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    /* Send a message to the server to setup the intent */

    var chain = '';
    lock(true);

    setAutoFocus(false);
    props.onChangeHideState(false);
    fetch("/account/ajax_create_setupintent", {
        method: "POST",
        body: security_name + '=' + security_token + chain,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
    })
    .then(res => {
        return res.json();
    })
    .then(response => {
        //console.log(data);
        if(response.status === 'OK')
        {
          var secret = response.data.secret;
          stripe.confirmCardSetup(secret, {
            payment_method: {
              type: "card",
              card: elements.getElement(CardNumberElement),
              billing_details: {name: name, address: {line1: address, city: town, postal_code: zip, country: country}, email: window.email },
            }
          }).then(function(result){
            if (result.error) {
              console.log('[error]', result.error);
              setError(result.error.message);
              lock(false);
              props.onChangeHideState(true);
            } else {
              lock(false);
              onOK();
              console.log('[PaymentMethod]', result.setupIntent);
              props.onChangeHideState(true);
            }
  
          });
      

        }
        else
        {
          lock(false);
          props.onChangeHideState(true);
        }
    }).catch(function (err) {
      lock(false);
      props.onChangeHideState(true);
    });  

    
    /*

    const {err, paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {name: name, address: {line1: address, city: town, postal_code: zip, country: country}, email: window.email },
    });

    if (err) {
      console.log('[error]', err);
      setError(err.mesage);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }
    */

  };

  const isValid = () =>
  {
    if(name.length > 0)
    {
      if(address.length > 0)
      {
        if(town.length > 0)
        {
          if(zip.length > 0)
          {
            if(state === true)
            {
              if(numberValid === true)
              {
                if(cvcValid === true)
                {
                  if(expiryValid === true)
                  {
                    setValid(true);
                    return;                    
                  }
                }
              }
            }
          }
        }
      }
    }
    setValid(false);
  }

  useEffect(() => {
    isValid();
  });


  const onUpdateName = ( event ) => {
    setName(event.target.value);
    console.log("update name", event.target.value);
  };
  const onUpdateAddress = ( event ) => {
    setAddress(event.target.value);
    console.log("update address", event.target.value);
  };
  const onUpdateTown = ( event ) => {
    setTown(event.target.value);
    console.log("update town", event.target.value);
  };
  const onUpdateZip = ( event ) => {
    setZip(event.target.value);
    console.log("update zip", event.target.value);
  };


  const onUpdateState = ( event ) => {
    setState(!state);
    console.log("update state", event.target.value, state);
  };
  const onUpdateCountry = ( event ) => {
    setCountry(event.target.value);
    console.log("update country", event.target.value);
  }; 
 
  return (
    <React.Fragment>       
        <form onSubmit={handleSubmit}>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
            
            <Grid item xs={12} md={5}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} >
                      <label>
                        Name
                        <input type="text" 
                              value={name} 
                              className='form-field' 
                              autoFocus={autofocus} 
                              required 
                              pattern="[0-9A-Za-z-., ]+" 
                              placeholder="Name on credit card" 
                              onChange={onUpdateName} />
                      </label>
                    </Grid>
                    <Grid item xs={12} >
                    <label>
                      Address
                      <input type="text" 
                      required 
                      className='form-field'                       
                      pattern="[0-9A-Za-z-., ]+" 
                      placeholder="Registered address" 
                      onChange={onUpdateAddress} />
                    </label>
                    </Grid> 
                    <Grid item xs={12} >
                      <label>
                        Town or city
                        <input type="text" 
                        required 
                        className='form-field' 
                        pattern="[0-9A-Za-z-., ]+" 
                        placeholder="Registered town" 
                        onChange={onUpdateTown} />
                      </label>
                    </Grid>  
                    <Grid item xs={12} >
                        <label>
                          Postal code or Zip code
                          <input type="text" 
                          required 
                          className='form-field' 
                          pattern="[0-9A-Za-z-., ]+" 
                          placeholder="Registered postcode" 
                          onChange={onUpdateZip} />
                        </label>
                    </Grid> 
                    <Grid item xs={12} >
                        <div className={classes.country}>
                        <label >Country</label>
                        </div>
                        <FormControl className={classes.formControl}>
                          <Select
                            id="country-select"
                            className="countrySelect"
                            value={country}
                            onChange={onUpdateCountry}
                            fullWidth={true}
                          >
                            <MenuItem value="GB">United Kingdom</MenuItem>
                            <MenuItem value="US">United States</MenuItem>
                            <MenuItem value="NZ">New Zealand</MenuItem>
                            <MenuItem value="AU">Australia</MenuItem>
                            <MenuItem value="AF">Afghanistan</MenuItem>
                            <MenuItem value="AX">Åland Islands</MenuItem>
                            <MenuItem value="AL">Albania</MenuItem>
                            <MenuItem value="DZ">Algeria</MenuItem>
                            <MenuItem value="AS">American Samoa</MenuItem>
                            <MenuItem value="AD">Andorra</MenuItem>
                            <MenuItem value="AO">Angola</MenuItem>
                            <MenuItem value="AI">Anguilla</MenuItem>
                            <MenuItem value="AQ">Antarctica</MenuItem>
                            <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                            <MenuItem value="AR">Argentina</MenuItem>
                            <MenuItem value="AM">Armenia</MenuItem>
                            <MenuItem value="AW">Aruba</MenuItem>
                            <MenuItem value="AT">Austria</MenuItem>
                            <MenuItem value="AZ">Azerbaijan</MenuItem>
                            <MenuItem value="BS">Bahamas</MenuItem>
                            <MenuItem value="BH">Bahrain</MenuItem>
                            <MenuItem value="BD">Bangladesh</MenuItem>
                            <MenuItem value="BB">Barbados</MenuItem>
                            <MenuItem value="BY">Belarus</MenuItem>
                            <MenuItem value="BE">Belgium</MenuItem>
                            <MenuItem value="BZ">Belize</MenuItem>
                            <MenuItem value="BJ">Benin</MenuItem>
                            <MenuItem value="BM">Bermuda</MenuItem>
                            <MenuItem value="BT">Bhutan</MenuItem>
                            <MenuItem value="BO">Bolivia</MenuItem>
                            <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                            <MenuItem value="BW">Botswana</MenuItem>
                            <MenuItem value="BV">Bouvet Island</MenuItem>
                            <MenuItem value="BR">Brazil</MenuItem>
                            <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                            <MenuItem value="BN">Brunei Darussalam</MenuItem>
                            <MenuItem value="BG">Bulgaria</MenuItem>
                            <MenuItem value="BF">Burkina Faso</MenuItem>
                            <MenuItem value="BI">Burundi</MenuItem>
                            <MenuItem value="KH">Cambodia</MenuItem>
                            <MenuItem value="CM">Cameroon</MenuItem>
                            <MenuItem value="CA">Canada</MenuItem>
                            <MenuItem value="CV">Cape Verde</MenuItem>
                            <MenuItem value="KY">Cayman Islands</MenuItem>
                            <MenuItem value="CF">Central African Republic</MenuItem>
                            <MenuItem value="TD">Chad</MenuItem>
                            <MenuItem value="CL">Chile</MenuItem>
                            <MenuItem value="CN">China</MenuItem>
                            <MenuItem value="CX">Christmas Island</MenuItem>
                            <MenuItem value="CC">Cocos (Keeling) Islands</MenuItem>
                            <MenuItem value="CO">Colombia</MenuItem>
                            <MenuItem value="KM">Comoros</MenuItem>
                            <MenuItem value="CG">Congo</MenuItem>
                            <MenuItem value="CD">Congo, The Democratic Republic of The</MenuItem>
                            <MenuItem value="CK">Cook Islands</MenuItem>
                            <MenuItem value="CR">Costa Rica</MenuItem>
                            <MenuItem value="CI">Cote D'ivoire</MenuItem>
                            <MenuItem value="HR">Croatia</MenuItem>
                            <MenuItem value="CU">Cuba</MenuItem>
                            <MenuItem value="CY">Cyprus</MenuItem>
                            <MenuItem value="CZ">Czechia</MenuItem>
                            <MenuItem value="DK">Denmark</MenuItem>
                            <MenuItem value="DJ">Djibouti</MenuItem>
                            <MenuItem value="DM">Dominica</MenuItem>
                            <MenuItem value="DO">Dominican Republic</MenuItem>
                            <MenuItem value="EC">Ecuador</MenuItem>
                            <MenuItem value="EG">Egypt</MenuItem>
                            <MenuItem value="SV">El Salvador</MenuItem>
                            <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                            <MenuItem value="ER">Eritrea</MenuItem>
                            <MenuItem value="EE">Estonia</MenuItem>
                            <MenuItem value="ET">Ethiopia</MenuItem>
                            <MenuItem value="FK">Falkland Islands (Malvinas)</MenuItem>
                            <MenuItem value="FO">Faroe Islands</MenuItem>
                            <MenuItem value="FJ">Fiji</MenuItem>
                            <MenuItem value="FI">Finland</MenuItem>
                            <MenuItem value="FR">France</MenuItem>
                            <MenuItem value="GF">French Guiana</MenuItem>
                            <MenuItem value="PF">French Polynesia</MenuItem>
                            <MenuItem value="TF">French Southern Territories</MenuItem>
                            <MenuItem value="GA">Gabon</MenuItem>
                            <MenuItem value="GM">Gambia</MenuItem>
                            <MenuItem value="GE">Georgia</MenuItem>
                            <MenuItem value="DE">Germany</MenuItem>
                            <MenuItem value="GH">Ghana</MenuItem>
                            <MenuItem value="GI">Gibraltar</MenuItem>
                            <MenuItem value="GR">Greece</MenuItem>
                            <MenuItem value="GL">Greenland</MenuItem>
                            <MenuItem value="GD">Grenada</MenuItem>
                            <MenuItem value="GP">Guadeloupe</MenuItem>
                            <MenuItem value="GU">Guam</MenuItem>
                            <MenuItem value="GT">Guatemala</MenuItem>
                            <MenuItem value="GG">Guernsey</MenuItem>
                            <MenuItem value="GN">Guinea</MenuItem>
                            <MenuItem value="GW">Guinea-bissau</MenuItem>
                            <MenuItem value="GY">Guyana</MenuItem>
                            <MenuItem value="HT">Haiti</MenuItem>
                            <MenuItem value="HM">Heard Island and Mcdonald Islands</MenuItem>
                            <MenuItem value="VA">Holy See (Vatican City State)</MenuItem>
                            <MenuItem value="HN">Honduras</MenuItem>
                            <MenuItem value="HK">Hong Kong</MenuItem>
                            <MenuItem value="HU">Hungary</MenuItem>
                            <MenuItem value="IS">Iceland</MenuItem>
                            <MenuItem value="IN">India</MenuItem>
                            <MenuItem value="ID">Indonesia</MenuItem>
                            <MenuItem value="IR">Iran, Islamic Republic of</MenuItem>
                            <MenuItem value="IQ">Iraq</MenuItem>
                            <MenuItem value="IE">Ireland</MenuItem>
                            <MenuItem value="IM">Isle of Man</MenuItem>
                            <MenuItem value="IL">Israel</MenuItem>
                            <MenuItem value="IT">Italy</MenuItem>
                            <MenuItem value="JM">Jamaica</MenuItem>
                            <MenuItem value="JP">Japan</MenuItem>
                            <MenuItem value="JE">Jersey</MenuItem>
                            <MenuItem value="JO">Jordan</MenuItem>
                            <MenuItem value="KZ">Kazakhstan</MenuItem>
                            <MenuItem value="KE">Kenya</MenuItem>
                            <MenuItem value="KI">Kiribati</MenuItem>
                            <MenuItem value="KP">Korea, Democratic People's Republic of</MenuItem>
                            <MenuItem value="KR">Korea, Republic of</MenuItem>
                            <MenuItem value="KW">Kuwait</MenuItem>
                            <MenuItem value="KG">Kyrgyzstan</MenuItem>
                            <MenuItem value="LA">Lao People's Democratic Republic</MenuItem>
                            <MenuItem value="LV">Latvia</MenuItem>
                            <MenuItem value="LB">Lebanon</MenuItem>
                            <MenuItem value="LS">Lesotho</MenuItem>
                            <MenuItem value="LR">Liberia</MenuItem>
                            <MenuItem value="LY">Libyan Arab Jamahiriya</MenuItem>
                            <MenuItem value="LI">Liechtenstein</MenuItem>
                            <MenuItem value="LT">Lithuania</MenuItem>
                            <MenuItem value="LU">Luxembourg</MenuItem>
                            <MenuItem value="MO">Macao</MenuItem>
                            <MenuItem value="MK">Macedonia, The Former Yugoslav Republic of</MenuItem>
                            <MenuItem value="MG">Madagascar</MenuItem>
                            <MenuItem value="MW">Malawi</MenuItem>
                            <MenuItem value="MY">Malaysia</MenuItem>
                            <MenuItem value="MV">Maldives</MenuItem>
                            <MenuItem value="ML">Mali</MenuItem>
                            <MenuItem value="MT">Malta</MenuItem>
                            <MenuItem value="MH">Marshall Islands</MenuItem>
                            <MenuItem value="MQ">Martinique</MenuItem>
                            <MenuItem value="MR">Mauritania</MenuItem>
                            <MenuItem value="MU">Mauritius</MenuItem>
                            <MenuItem value="YT">Mayotte</MenuItem>
                            <MenuItem value="MX">Mexico</MenuItem>
                            <MenuItem value="FM">Micronesia, Federated States of</MenuItem>
                            <MenuItem value="MD">Moldova, Republic of</MenuItem>
                            <MenuItem value="MC">Monaco</MenuItem>
                            <MenuItem value="MN">Mongolia</MenuItem>
                            <MenuItem value="ME">Montenegro</MenuItem>
                            <MenuItem value="MS">Montserrat</MenuItem>
                            <MenuItem value="MA">Morocco</MenuItem>
                            <MenuItem value="MZ">Mozambique</MenuItem>
                            <MenuItem value="MM">Myanmar</MenuItem>
                            <MenuItem value="NA">Namibia</MenuItem>
                            <MenuItem value="NR">Nauru</MenuItem>
                            <MenuItem value="NP">Nepal</MenuItem>
                            <MenuItem value="NL">Netherlands</MenuItem>
                            <MenuItem value="AN">Netherlands Antilles</MenuItem>
                            <MenuItem value="NC">New Caledonia</MenuItem>
                            <MenuItem value="NZ">New Zealand</MenuItem>
                            <MenuItem value="NI">Nicaragua</MenuItem>
                            <MenuItem value="NE">Niger</MenuItem>
                            <MenuItem value="NG">Nigeria</MenuItem>
                            <MenuItem value="NU">Niue</MenuItem>
                            <MenuItem value="NF">Norfolk Island</MenuItem>
                            <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                            <MenuItem value="NO">Norway</MenuItem>
                            <MenuItem value="OM">Oman</MenuItem>
                            <MenuItem value="PK">Pakistan</MenuItem>
                            <MenuItem value="PW">Palau</MenuItem>
                            <MenuItem value="PS">Palestinian Territory, Occupied</MenuItem>
                            <MenuItem value="PA">Panama</MenuItem>
                            <MenuItem value="PG">Papua New Guinea</MenuItem>
                            <MenuItem value="PY">Paraguay</MenuItem>
                            <MenuItem value="PE">Peru</MenuItem>
                            <MenuItem value="PH">Philippines</MenuItem>
                            <MenuItem value="PN">Pitcairn</MenuItem>
                            <MenuItem value="PL">Poland</MenuItem>
                            <MenuItem value="PT">Portugal</MenuItem>
                            <MenuItem value="PR">Puerto Rico</MenuItem>
                            <MenuItem value="QA">Qatar</MenuItem>
                            <MenuItem value="RE">Reunion</MenuItem>
                            <MenuItem value="RO">Romania</MenuItem>
                            <MenuItem value="RU">Russian Federation</MenuItem>
                            <MenuItem value="RW">Rwanda</MenuItem>
                            <MenuItem value="SH">Saint Helena</MenuItem>
                            <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                            <MenuItem value="LC">Saint Lucia</MenuItem>
                            <MenuItem value="PM">Saint Pierre and Miquelon</MenuItem>
                            <MenuItem value="VC">Saint Vincent and The Grenadines</MenuItem>
                            <MenuItem value="WS">Samoa</MenuItem>
                            <MenuItem value="SM">San Marino</MenuItem>
                            <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                            <MenuItem value="SA">Saudi Arabia</MenuItem>
                            <MenuItem value="SN">Senegal</MenuItem>
                            <MenuItem value="RS">Serbia</MenuItem>
                            <MenuItem value="SC">Seychelles</MenuItem>
                            <MenuItem value="SL">Sierra Leone</MenuItem>
                            <MenuItem value="SG">Singapore</MenuItem>
                            <MenuItem value="SK">Slovakia</MenuItem>
                            <MenuItem value="SI">Slovenia</MenuItem>
                            <MenuItem value="SB">Solomon Islands</MenuItem>
                            <MenuItem value="SO">Somalia</MenuItem>
                            <MenuItem value="ZA">South Africa</MenuItem>
                            <MenuItem value="GS">South Georgia and The South Sandwich Islands</MenuItem>
                            <MenuItem value="ES">Spain</MenuItem>
                            <MenuItem value="LK">Sri Lanka</MenuItem>
                            <MenuItem value="SD">Sudan</MenuItem>
                            <MenuItem value="SR">Suriname</MenuItem>
                            <MenuItem value="SJ">Svalbard and Jan Mayen</MenuItem>
                            <MenuItem value="SZ">Swaziland</MenuItem>
                            <MenuItem value="SE">Sweden</MenuItem>
                            <MenuItem value="CH">Switzerland</MenuItem>
                            <MenuItem value="SY">Syrian Arab Republic</MenuItem>
                            <MenuItem value="TW">Taiwan, Province of China</MenuItem>
                            <MenuItem value="TJ">Tajikistan</MenuItem>
                            <MenuItem value="TZ">Tanzania, United Republic of</MenuItem>
                            <MenuItem value="TH">Thailand</MenuItem>
                            <MenuItem value="TL">Timor-leste</MenuItem>
                            <MenuItem value="TG">Togo</MenuItem>
                            <MenuItem value="TK">Tokelau</MenuItem>
                            <MenuItem value="TO">Tonga</MenuItem>
                            <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                            <MenuItem value="TN">Tunisia</MenuItem>
                            <MenuItem value="TR">Turkey</MenuItem>
                            <MenuItem value="TM">Turkmenistan</MenuItem>
                            <MenuItem value="TC">Turks and Caicos Islands</MenuItem>
                            <MenuItem value="TV">Tuvalu</MenuItem>
                            <MenuItem value="UG">Uganda</MenuItem>
                            <MenuItem value="UA">Ukraine</MenuItem>
                            <MenuItem value="AE">United Arab Emirates</MenuItem>
                            <MenuItem value="GB">United Kingdom</MenuItem>
                            <MenuItem value="US">United States</MenuItem>
                            <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                            <MenuItem value="UY">Uruguay</MenuItem>
                            <MenuItem value="UZ">Uzbekistan</MenuItem>
                            <MenuItem value="VU">Vanuatu</MenuItem>
                            <MenuItem value="VE">Venezuela</MenuItem>
                            <MenuItem value="VN">Viet Nam</MenuItem>
                            <MenuItem value="VG">Virgin Islands, British</MenuItem>
                            <MenuItem value="VI">Virgin Islands, U.S.</MenuItem>
                            <MenuItem value="WF">Wallis and Futuna</MenuItem>
                            <MenuItem value="EH">Western Sahara</MenuItem>
                            <MenuItem value="YE">Yemen</MenuItem>
                            <MenuItem value="ZM">Zambia</MenuItem>
                            <MenuItem value="ZW">Zimbabwe</MenuItem>

                          </Select>
                        </FormControl>                    

                    </Grid>                                                                                                                     
                </Grid>
            </Grid>          
            <Hidden smDown>
                <div className={classes.divider}>
                    <Divider orientation="vertical" flexItem />            
                </div>
            </Hidden>

            
            <Grid item  xs={12} md={5} >
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} >
                      <label>
                        Card number
                        <CardNumberElement
                          options={options}
                          onReady={() => {
                            console.log("CardNumberElement [ready]");
                          }}
                          onChange={event => {
                            console.log("CardNumberElement [change]", event);
                            setNumberValid(event.complete);

                          }}
                          onBlur={() => {
                            console.log("CardNumberElement [blur]");
                          }}
                          onFocus={() => {
                            console.log("CardNumberElement [focus]");
                          }}
                        />
                      </label>                    
                    </Grid>
                    <Grid item xs={12} >
                      <label>
                        Expiration date
                        <CardExpiryElement
                          options={options}
                          onReady={() => {
                            console.log("CardExpiryElement [ready]");
                          }}
                          onChange={event => {
                            console.log("CardExpiryElement [change]", event);
                            setExpiryValid(event.complete);

                          }}
                          onBlur={() => {
                            console.log("CardExpiryElement [blur]");
                          }}
                          onFocus={() => {
                            console.log("CardExpiryElement [focus]");
                          }}
                        />
                      </label>                    
                    </Grid>
                    <Grid item xs={12} >
                      <label>
                        CVC
                        <CardCvcElement
                          options={options}
                          onReady={() => {
                            console.log("CardNumberElement [ready]");
                          }}
                          onChange={event => {
                            console.log("CardNumberElement [change]", event);
                            setCVCValid(event.complete);
                          }}
                          onBlur={() => {
                            console.log("CardNumberElement [blur]");
                          }}
                          onFocus={() => {
                            console.log("CardNumberElement [focus]");
                          }}
                        />
                      </label>                    
                    </Grid>
                    <Grid item xs={12} >
                        <div className="mandate">

                            <FormControlLabel
                              control={<Checkbox checked={state} onChange={onUpdateState} name="mandate" />}
                              label={lab}  
                              labelPlacement="end"
                              className={classes.consent}
                            />

                                  
                        </div>
                    </Grid>                                      
                </Grid>                
            </Grid>
            <Grid item xs={12}>
              <div className="danger">
                  {error}
              </div>
            </Grid>
            <Grid item xs={12} >
                <Button type="submit" fullWidth disabled={!stripe || !valid} className={classes.right} variant="contained" color="primary">
                  Save card
                </Button>
            </Grid>  

        </Grid>
      </form>     
         
      
    </React.Fragment>
  );
};



const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);





class AddStripeCardDialog extends Component {

    constructor( props )
    {
        super( props );
        console.log("AddStripeCardDialog", props);

        this.state = {
  
            error: {},
            refresh: false,
            stripePromise: null,
            oncancel: props.onCancel,
            onOK: props.onOK,
            show: true,
        };

        this.state.stripePromise = loadStripe(window.key, {stripeAccount: window.acct});

        this.handleClose = this.handleClose.bind(this);
        this.handleOK = this.handleOK.bind(this);


    }



    handleOK(){

    }


  
    handleClose = () => {
      this.state.oncancel();
      //this.setState({'open': false,  'selectedDays': []});
    }


    render(){

        var classes = this.props.classes;
        const dclass = this.state.show ? '' : 'hideWidget'

        return (
          <div>
            <MuiThemeProvider theme={theme}>
              <form onSubmit={this.handleSubmit}>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" className={dclass} open={this.props.state} fullWidth={true} maxWidth = {'md'}>
                  <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={false}>
                    Add credit or debit card
                  </DialogTitle>
                  <DialogContent dividers>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>                          
                        <Grid item xs={12} >
                          <Elements stripe={this.state.stripePromise}>
                            <CardForm classes={classes} company={this.props.company} lock={this.props.lock} onOK={this.props.onOK} onChangeHideState={(s) => {
                              console.log('new show', s);
                              this.setState({'show': s}) 
                              }}/>
                          </Elements>
                        </Grid>
                    </Grid> 
                            
                  </DialogContent>
  
                </Dialog>
              </form>  
            </MuiThemeProvider>                
          </div>
        );
    }


}






export default withStyles(styles)(AddStripeCardDialog);

