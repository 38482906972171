import React from 'react';


import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    Processing:
    {
        paddingTop: '250px',
        textAlign: 'center',
        color: '#fff'
    },
    instruct:{
        marginTop: '30px',
        fontSize: '1.4em'
    }


});



function Processing(props) {

        return <div className={props.classes.Processing}>
                <i className="fa fa-refresh fa-spin fa-5x fa-fw"></i>
                <div className={props.classes.instruct}>please wait</div>
            </div>
}

export default withStyles(styles)(Processing);


