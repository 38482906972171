import React from 'react';


import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    loading:
    {
        textAlign: 'center',
        color: 'rgba(0,0,0,0.2)'
    },
    instruct:{
        marginTop: '15px',
        fontSize: '1.4em'
    }


});



function Loading(props) {

        return <div className={props.classes.loading}>
                <i className="fa fa-refresh fa-spin fa-5x fa-fw"></i>
                <span className="sr-only">Loading...</span>
                <div className={props.classes.instruct}>Please wait. Loading...</div>
            </div>
}

export default withStyles(styles)(Loading);


