import React from 'react';
import { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import AddStripeCreditCard from '../stripe-credit-card/AddStripeCardDialog';
import Processing from '../processing';
import Alert from '@material-ui/lab/Alert';

import Select  from '@material-ui/core/Select';
import PaymentIcon from '@material-ui/icons/Payment';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'rgba(43, 59, 76, 0.8)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  consent:{
    alignItems:'flex-start',
    '& .MuiFormControlLabel-label':{
      marginTop: '8px',
      fontSize: '0.9em',
      color: 'rgba(35, 35, 51, 1)',

    }
  },
  sectionHeader:{
    color: 'rgba(43, 59, 76, 0.8)',
    textAlign: 'center',
    fontSize: "1.5em",
    textTransform: "uppercase"    
  },
  rightLink:{
    textAlign:'right',
    '& a':{
      fontSize: '0.8em',
    }
  } 
}));

export default function SelectCard( props ) {
    const classes = useStyles();
    const invoice_id = props.id || null;
    const callback = props.callback;
    const security = props.security;
    const [cards, setCards] = useState(props.payment_methods|| []);
    const [card, setCard] = useState('');
    const [addcard, setAddCard] = useState(false);
    const [locked, onLock] = useState(false);
    const [accepted, setAccept] = useState(false);
    const [terms, setTerms] = useState(false);
    const [error, setError] = useState('');

    const tc_label = (<span>I have read and agree to the <a href={window.terms} target="_blank" rel="noopener noreferrer">Terms and conditions</a></span>)

  
    const onChangeCard = (event) => {

      if(event.target.value === -1)
      {
        setCard('');
        setAddCard(true);

      }
      else
      {
        setCard(event.target.value)
      }
    }

    const onAcceptedChange = (event) => {
      console.log("On accept change", event.target.checked)
      setAccept(event.target.checked)
    }

    const onTermsChange = (event) => {
      console.log("On terms change", event.target.checked)
      setTerms(event.target.checked)
    }

    const onAddCardCancel = (event ) => {
      setCard('');
      setAddCard(false);
    }

    const onAddCardClick = (event) => {
      setCard('');
      setAddCard(true);
    }

    
    const onAddCardDone = () =>
    {
        var chain = '';
        onLock(true)
        fetch("/account/ajax_account_payments_refresh", {
            method: "POST",
            body: security.name + '=' + security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
              console.log("Updating the data ", response.payments);
              setCards(response.payments.data);
              setAddCard(false);
              onLock(false)
            }
            else
            {

              onLock(false)
            }
        }, (error) => {
          console.log("Failed to communicate with the server ");
          onLock(false)
        })  
  
    }    
 
    const onComplete = (event) => {

        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var chain = '';
        if(invoice_id === null)
        {
            chain = '&card=' + JSON.stringify(card);
            chain    += '&segment=' + window.segment;
            onLock(true);

            fetch("/subscription/ajax_do_subscribe", {
                method: "POST",
                body: security.name + '=' + security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    onLock(false);
                    callback(true);
                }
                else
                {
                  onLock(false);
                  setError(response.message);
                }
            }, (error) => {
              onLock(false);
              setError('Network error. Please try again:' + error);
            });   
        }
        else
        {
          chain = '&card=' + JSON.stringify(card);
          chain    += '&invoice_id=' + invoice_id;
          onLock(true);
  
          fetch("/account/ajax_invoice_swap_payment_source", {
              method: "POST",
              body: security.name + '=' + security.token + chain,
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "X-Requested-With": 'XMLHttpRequest',
                  "X_REQUESTED_WITH": 'xmlhttprequest'
              }
          })
          .then(res => {
              return res.json();
          })
          .then(response => {
              //console.log(data);
              if(response.status === 'OK')
              {
                  onLock(false);
                  callback(true);
              }
              else
              {
                onLock(false);
                setError(response.message);
              }
          }, (error) => {
            onLock(false);
            setError('Network error. Please try again:' + error);
          });   
        }    
    }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <div className={classes.paper}>

        <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1} >
            <Grid item>
                  <Avatar className={classes.avatar}>
                    <PaymentIcon /> 
                  </Avatar>
            </Grid>
            <Grid item>
              <div className={classes.sectionHeader}>
              Payment
              </div>
            </Grid>
            <Grid item xs={12} className={classes.rightLink}>
              <Link href="#" variant="body2" onClick={onAddCardClick} >
                {<span>+ Add new card</span>}
              </Link>              
            </Grid>
            <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                    <InputLabel id="zoom-account-select">Choose payment option</InputLabel>

                    <Select
                    id="credit-card"
                    value={card}
                    label="Choose payment option"
                    onChange={onChangeCard}
                    style={{'width': '100%'}}
                    >
                    <MenuItem value={-1} key={-1}><em>Add new credit or debit card.</em></MenuItem>
                    {cards.map(el => (
                        <MenuItem value={el.id} key={el.id}><em>{'xxxx xxxx xxxx ' + el.card.last4 + ' (Expires: ' + el.card.exp_month.toString() + '/' + el.card.exp_year.toString() + ' )'}</em></MenuItem>
                    ))}                                            
                    </Select>    
                    <FormHelperText>Select a payment type.</FormHelperText>                                                 
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="mandate">
                <FormControlLabel
                  control={<Checkbox 
                              key="mandate-accept"
                              value={1} 
                              color="primary"
                              checked={accepted}
                              onChange={onAcceptedChange}
                              />}
                  label={window.label}
                  labelPlacement="end"
                  className={classes.consent}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="mandate">
                <FormControlLabel
                  control={<Checkbox 
                              key="terms-accept"
                              value={1} 
                              color="primary"
                              checked={terms}
                              onChange={onTermsChange}
                              />}
                  label={tc_label}
                  labelPlacement="end"
                  className={classes.consent}
                />
              </div>
            </Grid>            
        </Grid>



        {error.length > 0 && (<Grid container>
              <Grid item xs>
                  <Alert severity="error">{error}</Alert>
              </Grid>
        </Grid>)}
                        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!accepted || !terms || card === ''}
            onClick={onComplete}
          >
            Complete setup
          </Button>
      </div>

      {addcard === true && (
      <AddStripeCreditCard state={true} company={window.company.name}  onCancel={onAddCardCancel} onOK={onAddCardDone} lock={onLock} />
      )}      
      {locked === true && (
          <div className="overlay">
              <Processing />  
          </div> 
      )}      
    </Container>
  );
}