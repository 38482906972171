import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  success:{
    color: 'rgba(43, 59, 76, 1)',
    textAlign: 'center',
    fontSize: "3em",
    textTransform: "uppercase"
  },
  what:{
    color: 'rgba(43, 59, 76, 0.4)',
    textAlign: 'center',
    fontSize: "1em",
    textTransform: "uppercase"
  }, 
  smSection:{
    color: '#2b3b4c',
    fontWeight: 'normal',
    lineHeight: '1.6em',
    textAlign: 'center',
  },
  avatarSuccess: {
    color: '#fff',
    width: '100px',
    height: '100px',
    backgroundColor: green[500],
    '& .ico':{
      width: '50px',
      height: '50px',
    }
  },
  icoWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'  
  },
  actionIco:{
    width: '100px',
    height: '100px',

  }    
}));

export default function SubscriptionComplete( props ) {
    const classes = useStyles();
  const onReturn = () => {
    window.location.replace(window.home);

  }
  const onAccount = () => {
    window.location.replace(window.account);
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />

      <div className={classes.paper}>

        <Grid container direction="row" justify="center" alignContent="flex-start" alignItems="flex-start" spacing={1} >
            <Grid item xs={12} className={classes.what}> 
                setup 
            </Grid>
            <Grid item xs={12} className={classes.success}> 
                Complete 
            </Grid>
            <Grid item> 
                  <Avatar className={classes.avatarSuccess}>
                        <ThumbUpIcon className="ico" />
                  </Avatar>            
            </Grid>
            <Grid item xs={12}>
              <p className={classes.smSection}>
                Your subscription setup is now complete. Payment will be initiated shortly and charged to your credit or debit. Subsequent payments will be charged to 
                credit or debit card in accordance to the terms of this subscription. To cancel your subscription, you must log in to your account. Please note that a minimum term may apply. 
              </p>
              <p className={classes.smSection}>
                You may now close this window or to view your subscriptions, click the 'view account' button below or return to website. 
              </p>
            </Grid>
            <Grid item>
                  <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  onClick={onReturn}
                >
                  Return to website
                </Button>              
            </Grid>
            <Grid item >
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onAccount}
                >
                  View subscriptions
                </Button>                
            </Grid>
        </Grid>          
      </div>
        
    </Container>
  );
}