import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import Parser from 'html-react-parser';
import uuidv1 from "uuid";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CoreDialog( props ) {
  const [open, setOpen] = React.useState(props.state);

  const handleClose = () => {
    props.onCancel();
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(props.state);
}, [props.state])

  
  console.log(props.state);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers>
          {Parser(props.body)}
          <Typography gutterBottom>
          </Typography>
        </DialogContent>
        <DialogActions>
            {props.actions.map(el => ( 
                <Button onClick={el.action} key={uuidv1.v1()} size="small" color={el.color} className={el.className} variant={el.variant}>
                      {el.label}
                </Button>
            ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}
