import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Loading from '../loading';
import uuidv1 from "uuid";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Processing from '../processing';
import parse from 'html-react-parser';


import {
  INVOICE_PAYMENT_STATUS_UNPAID,
  INVOICE_PAYMENT_STATUS_PAID,
  INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING,
  INVOICE_PAYMENT_STATUS_PAYMENT_ACTION,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM,
  INVOICE_PAYMENT_STATUS_PAYMENT_ERROR,

  INVOICE_PARENT_TYPE_SUBSCRIPTION
} from '../types';



import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    textCenter:{
      textAlign: 'center'
    },        
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    },      

});



class ManageButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ManageButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabManage} aria-label="Delete Pass" onClick={this._handleClick}>
              <ArrowForwardIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}

class InvoiceManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      invoices: window.invoices || [],
      error: false,
      error_str: '',
      manage_invoice: false,
      security: {name: window.security_name, token: window.security_token},
      loading: false,
      manage_invoice_focus: {},
      locked: false,
      reload: false,
    };

    this.onManageInvoice = this.onManageInvoice.bind(this);
    this.onLock  = this.onLock.bind(this);
    this.interpretState  = this.interpretState.bind(this);
  }
  onLock( v )
  {
    this.setState({'locked': v});
  }
  onManageInvoice( obj )
  {
    window.location.href="/account/invoice/" + obj.id;
  }

  interpretState( s)
  {
      switch(s)
      {
        case INVOICE_PAYMENT_STATUS_UNPAID:
        {
          return 'Unpaid';
        }
        case INVOICE_PAYMENT_STATUS_PAID:
        {
          return 'Paid';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING:
        {
          return 'Processing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ACTION:
        {
          return 'Action required';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE:
        {
          return 'Capturing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM:
        {
          return 'Confirming  payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ERROR:
        {
          return 'Payment error';
        }
        
        default:  
        {
          return 'unknown';
        }
        
      }

  }
  interpretParentType( s, v )
  {
      switch(s)
      {
        case INVOICE_PARENT_TYPE_SUBSCRIPTION:
        {
          const x  ='<a href="/account/subscription/' + v + '">Subscription ' + v + '</a>';
          return parse(x);
          //return x;
        }        
        default:  
        {
          return 'unknown';
        }
        
      }

  }


  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className="appBar">
            <Toolbar>
              <Typography variant="h2" className={classes.title}>
                  Invoice manager
              </Typography>
            </Toolbar>
        </AppBar>


        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Date 
                        </TableCell>  
                        <TableCell className={classes.cell}>
                          Date due
                        </TableCell>         
                        <TableCell className={classes.cellCenter}>
                          Status
                        </TableCell>  
                        <TableCell className={classes.cellCenter}>
                          Created by
                        </TableCell>           
                        <TableCell className={classes.cellCenter}>
                          Cost
                        </TableCell>                                         
                        <TableCell className={classes.cell}>
                          Detail
                        </TableCell>                                                                                                  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.loading === false && this.state.invoices.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={classes.empty}>No invoices.</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.loading === false && this.state.invoices.filter(function(obj) {
                          return obj.archived === true? false:true;
                    }).map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          {el.invoice_id}
                        </TableCell>
                        <TableCell>
                          {moment(el.created).format("LL")}
                        </TableCell>  
                        <TableCell>
                          {moment(el.due).format("LL")}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {this.interpretState(el.state)}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {this.interpretParentType(el.parent_type, el.parent_id)}
                        </TableCell>  
                        <TableCell className={classes.textCenter}>
                          {el.currency + ' ' + parseFloat(el.gross).toFixed(2)}
                        </TableCell>                                                                          
                        <TableCell>
                          <ManageButton data={el} classes={classes} callback={this.onManageInvoice}/>
                        </TableCell>                                                                                  
                      </TableRow>
                    ))}
                    {this.state.loading === true && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}                    
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </div>
        {this.state.locked === true && (
          <div className="overlay">
              <Processing />  
          </div> 
        )}


      </div> 

    );
  }
}

export default withStyles(styles)(InvoiceManager);
