import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import moment from 'moment'
import uuidv1 from "uuid";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import parse from 'html-react-parser';
import Divider from '@material-ui/core/Divider';
import {
  INVOICE_PAYMENT_STATUS_UNPAID,
  INVOICE_PAYMENT_STATUS_PAID,
  INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING,
  INVOICE_PAYMENT_STATUS_PAYMENT_ACTION,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM,
  INVOICE_PAYMENT_STATUS_PAYMENT_ERROR,
} from '../types';

import {
  SUBSCRIPTION_MANDATE_STATUS_ERROR,
  SUBSCRIPTION_MANDATE_STATUS_MANUAL,
  SUBSCRIPTION_MANDATE_STATUS_ACTIVE,
  SUBSCRIPTION_MANDATE_STATUS_PAUSED,
  SUBSCRIPTION_MANDATE_STATUS_DELETED,
  SUBSCRIPTION_MANDATE_STATUS_SUSPENDED,
} from '../types';


import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    invoiceblocks:{
      fontWeight: 'bold',
      fontSize: '0.9em',
      color: 'rgba(0,10,30,.75)'
    },
    invoiceDetail:{
      fontWeight: '400',
      color: 'rgba(0,0,0,0.6)',
      fontSize: '0.9em',
      marginBottom: '5px',
    }, 
    taxTitle:{
      marginTop: '40px',
      marginBottom: '40px',
      fontWeight: 'bold',
    }, 
    spacer50px:{
      height: '50px',
      display: 'block',
      width:'100%'
    },       
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    tableHeaderDescription:{
      width: '50%',
      fontWeight: 'bold',
    },
    tableSubtotal:{
      textAlign: 'right',
      fontWeight: 'bold',
      '> span':{
        fontSize: '1.2em'
      }
    },    
    textCenter:{
      textAlign: 'center'
    },
    textRight:{
      textAlign: 'right'
    },      
    tableHeaderLeft:{
      textAlign: 'left',
      fontWeight: 'bold',
    }, 
    tableHeaderCenter:{
      textAlign: 'center',
      fontWeight: 'bold',
    },                
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    },
    small:{
      fontSize: '0.8em',
    }      

});



class ManageButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ManageButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabManage} aria-label="Delete Pass" onClick={this._handleClick}>
              <ArrowForwardIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}

class Subscription extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      subscription: window.subscription || {},
      company: window.company,
      error: false,
      security: {name: window.security_name, token: window.security_token},
    };

    this.interpretState  = this.interpretState.bind(this);
    this.interpretSubscriptionState  = this.interpretSubscriptionState.bind(this);
    this.onManageInvoice = this.onManageInvoice.bind(this);
    
  }

  onManageInvoice( obj )
  {
    window.location.href="/account/invoice/" + obj.invoice_id;
  }

  interpretState( s)
  {
      var classes = this.props.classes;

      switch(s)
      {
        case INVOICE_PAYMENT_STATUS_UNPAID:
        {
          return 'Unpaid';
        }
        case INVOICE_PAYMENT_STATUS_PAID:
        {
          return 'Paid';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING:
        {
          return 'Processing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ACTION:
        {
          return 'Action required';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE:
        {
          return 'Capturing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM:
        {
          return 'Confirming  payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ERROR:
        {
          return parse("<div class=" + classes.error + ">Payment error</div>");
        }
        
        default:  
        {
          return 'unknown';
        }
        
      }

  }


  interpretSubscriptionState( s)
  {

      switch(s)
      {
        case SUBSCRIPTION_MANDATE_STATUS_ERROR:
        {
          return 'Error';
        }
        case SUBSCRIPTION_MANDATE_STATUS_MANUAL:
        case SUBSCRIPTION_MANDATE_STATUS_ACTIVE:
        {
          return 'Active';
        }
        case SUBSCRIPTION_MANDATE_STATUS_PAUSED:
        {
          return 'Paused';
        }
        case SUBSCRIPTION_MANDATE_STATUS_DELETED:
        {
          return 'Cancelled';
        }
        case SUBSCRIPTION_MANDATE_STATUS_SUSPENDED:
        {
          return 'Suspended';
        }
        
        default:  
        {
          return 'unknown';
        }
        
      }

  }


  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>



        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

              <Grid item xs={9}>
                  <h2 className={classes.taxTitle}>
                      Subscription overview      
                  </h2>
              </Grid>
              <Grid item>
                  {window.logo.length > 0 && (
                      <img src={window.logo} alt="thank you" style={{'maxHeight': '100px'}}/>
                  )}             
              </Grid>              
              <Grid item xs={12}>
                  <Divider />               
              </Grid>
              <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>ID</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.state.subscription.id}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Status</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.interpretSubscriptionState(this.state.subscription.state)}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Created</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{moment(this.state.subscription.created).format("LL")}</div>
                        </Grid>                      
                  </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Next payment</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{moment(this.state.subscription.due).format("LL")}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Amount</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.state.subscription.currency} {parseFloat(this.state.subscription.gross).toFixed(2)}</div>
                        </Grid>                        
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Contract ends</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{moment(this.state.subscription.term).format("LL")}</div>
                        </Grid>  
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Divider />               
              </Grid>
              <Grid item>
                  <div className={classes.spacer50px}></div>
              </Grid>
              <Grid item xs={12}>
                  <h2 className={classes.taxTitle}>
                      Invoices      
                  </h2>
              </Grid>              
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Date 
                        </TableCell>  
                        <TableCell className={classes.cell}>
                          Date due
                        </TableCell>         
                        <TableCell className={classes.cellCenter}>
                          Status
                        </TableCell>  
         
                        <TableCell className={classes.cellCenter}>
                          Cost
                        </TableCell>                                         
                        <TableCell className={classes.cell}>
                          Detail
                        </TableCell>                                                                                                  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.subscription.invoices.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={classes.empty}>No invoices.</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.subscription.invoices.filter(function(obj) {
                          return obj.archived === true? false:true;
                    }).map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          {el.invoice_id}
                        </TableCell>
                        <TableCell>
                          {moment(el.created).format("LL")}
                        </TableCell>  
                        <TableCell>
                          {moment(el.due).format("LL")}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {this.interpretState(el.state)}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {el.currency + ' ' + parseFloat(el.gross).toFixed(2)}
                        </TableCell>                                                                          
                        <TableCell>
                          <ManageButton data={el} classes={classes} callback={this.onManageInvoice}/>
                        </TableCell>                                                                                  
                      </TableRow>
                    ))}                   
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>

          </Grid>
        </div>
      </div> 

    );
  }
}

export default withStyles(styles)(Subscription);
