import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddStripeCardDialogue from './AddStripeCardDialog';
import CoreDialog from '../dialog';
import moment from 'moment'
import Loading from '../loading';
import uuidv1 from "uuid";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Processing from '../processing';
import Alert from '@material-ui/lab/Alert';


import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    card:{
      fontWeight: '600',
    },
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    },      

});



class DeleteButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('DeleteButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Pass" onClick={this._handleClick}>
              <DeleteForeverIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}

class StripeCreditCardManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      add_card: false,
      payment_methods: window.payment_methods || [],
      error: false,
      error_str: '',
      delete_card: false,
      security: {name: window.security_name, token: window.security_token},
      loading: false,
      delete_focus: {},
      locked: false,
      reload: false,
    };

    this.currentSend = 0;


 //   this.submit  = this.submit.bind(this);
    this.onAddCard = this.onAddCard.bind(this);
    this.onCancelDelete = this.onCancelDelete.bind(this);
    this.onDeleteCard = this.onDeleteCard.bind(this);
    this.onCompleteDelete = this.onCompleteDelete.bind(this);
    this.onAddCardCancel  = this.onAddCardCancel.bind(this);
    this.onAddCardDone  = this.onAddCardDone.bind(this);
    this.onLock  = this.onLock.bind(this);
  }
  onLock( v )
  {
    this.setState({'locked': v});
  }
  onDeleteCard( obj )
  {
    this.setState({'delete_card': true, 'delete_focus': obj});
  }
  onCancelDelete()
  {
    this.setState({'delete_card': false});
  }

  onAddCard()
  {
    this.setState({'add_card': !this.state.add_card});
  }

  onCompleteDelete( )
  {
    console.log(this.state.delete_focus);
    var chain = '&id=' + this.state.delete_focus.id;
    var _this = this;

    fetch("/account/ajax_account_detach_payment", {
        method: "POST",
        body: this.state.security.name + '=' + this.state.security.token + chain,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
    })
    .then(res => {
        return res.json();
    })
    .then(response => {
        //console.log(data);
        if(response.status === 'OK')
        {
          _this.onAddCardDone()
          _this.setState({'delete_card': false});

        }
        else
        {
            _this.setState({'delete_card': false, error: true, error_str: response.reason});
        }
    }).catch(function (err) {
      _this.setState({'delete_card': false, error: true, error_str: 'Network connection error. Please refresh your browser and try again.'});
    });    
  }

  onAddCardDone()
  {
      var chain = '';
      var _this = this;
      this.setState({'loading': true});
      fetch("/account/ajax_account_payments_refresh", {
          method: "POST",
          body: this.state.security.name + '=' + this.state.security.token + chain,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest'
          }
      })
      .then(res => {
          return res.json();
      })
      .then(response => {
          //console.log(data);
          if(response.status === 'OK')
          {
            console.log("Updating the data ", response.payments);
            _this.setState({'add_card': false, 'loading': false, 'payment_methods': response.payments.data, 'reload': !this.state.reload});      
          }
          else
          {
            _this.setState({'add_card': false, 'loading': false});      
          }
      }).catch(function (err) {

        _this.setState({'add_card': false, 'loading': false});      
      });  

  }
  onAddCardCancel()
  {
    this.setState({'add_card': !this.state.add_card});
  }
  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className="appBar">
            <Toolbar>
              <Typography variant="h2" className={classes.title}>
                  Credit card manager
              </Typography>
              <Button  variant="outlined" color="primary" disabled={window.disabled} onClick={this.onAddCard}>Add card</Button>
            </Toolbar>
        </AppBar>


        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                {this.state.error === true && 
                  (<div>
                    <Alert severity="error">{this.state.error_str}</Alert>
                  </div>)
                }
              </Grid>
              
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.card}>
                          Card number
                        </TableCell>
                        <TableCell className={classes.card}>
                          Expires
                        </TableCell>  
                        <TableCell className={classes.card}>
                          Added
                        </TableCell>         
                        <TableCell className={classes.card}>
                          Delete
                        </TableCell>                                                                          
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.loading === false && this.state.payment_methods.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className={classes.empty}>No cards available. Please click 'Add card' to add a new card</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.loading === false && this.state.payment_methods.filter(function(obj) {
                          return obj.type === 'card'? true:false;
                    }).map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          xxxx xxxx xxxx {el.card.last4}
                        </TableCell>
                        <TableCell>
                          {el.card.exp_month}/{el.card.exp_year} 
                        </TableCell>  
                        <TableCell>
                          {moment.unix(el.created).format("LL")}
                        </TableCell> 
                        <TableCell>
                          <DeleteButton data={el} classes={classes} callback={this.onDeleteCard}/>
                        </TableCell>                                                                                  
                      </TableRow>
                    ))}
                    {this.state.loading === true && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}                    
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </div>
        {this.state.add_card === true && (
          <AddStripeCardDialogue state={true} company={window.company}  onCancel={this.onAddCardCancel} onOK={this.onAddCardDone} lock={this.onLock} />
        )}
        {this.state.delete_card === true && (<CoreDialog title="Delete Card" body="You are about to delete this card. Do you want to continue?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"OK", action:this.onCompleteDelete}]}  />)}
        {this.state.locked === true && (
          <div className="overlay">
              <Processing />  
          </div> 
        )}


      </div> 

    );
  }
}

export default withStyles(styles)(StripeCreditCardManager);
