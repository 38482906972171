import React from 'react';
import {  useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'rgba(43, 59, 76, 0.8)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  sectionHeader:{
    color: 'rgba(43, 59, 76, 0.8)',
    textAlign: 'center',
    fontSize: "1.5em",
    textTransform: "uppercase"    
  },  
}));

export default function SignIn( props ) {
    const classes = useStyles();
    const callback = props.callback;
    const label = props.label || "Sign in";
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const security = props.security;


    const onSetEmail = (event) => {
        setEmail(event.target.value);
    }

    const onSetPassword = (event) => {
        setPassword(event.target.value);
    }

    const signIn = (event) => {

        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var chain = '&username=' + email;
        chain    += '&password=' + password;

        fetch("/account/ajax_account_signin", {
            method: "POST",
            body: security.name + '=' + security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                callback(true, response.payment_methods);
            }
            else
            {
                setError(response.message);
            }
        }, (error) => {
            setError('Network error. Please try again: ' + error);
        }).catch(error => {
          setError('Network error. Please try again: ' + error);
        });      
    }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="center" spacing={1} >
            <Grid item>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <div className={classes.sectionHeader}>
                  Sign in
              </div>
            </Grid>

        </Grid>
 

        <form className={classes.form} validate={"true"} onSubmit={signIn}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            onChange={onSetEmail}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={onSetPassword}
            value={password}
            autoComplete="current-password"
          />

          {error.length > 0 && (<Grid container>
              <Grid item xs>
                  <Alert severity="error">{error}</Alert>
              </Grid>
          </Grid>)}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {label}
          </Button>

          <Grid container>
              <Grid item xs>
                <Link href={window.host_signup} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href={window.account_signup} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}