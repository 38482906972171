import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import Loading from '../loading';
import uuidv1 from "uuid";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import Processing from '../processing';
import CoreDialog from '../dialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Alert from '@material-ui/lab/Alert';


import {
  SUBSCRIPTION_MANDATE_STATUS_ERROR,
  SUBSCRIPTION_MANDATE_STATUS_MANUAL,
  SUBSCRIPTION_MANDATE_STATUS_ACTIVE,
  SUBSCRIPTION_MANDATE_STATUS_PAUSED,
  SUBSCRIPTION_MANDATE_STATUS_DELETED,
  SUBSCRIPTION_MANDATE_STATUS_SUSPENDED,
  INVOICE_PARENT_TYPE_SUBSCRIPTION
} from '../types';



import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    textCenter:{
      textAlign: 'center'
    }, 
    textCenterError:{
      textAlign: 'center',
    },            
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    }, 
    fabDelete:{
      backgroundColor: '#b80000',
      color: '#fff',
      '&:hover':{
          backgroundColor: '#940000',
      },
      '&.Mui-disabled':{
        backgroundColor: 'rgba(0,0,0,0.1)',
        
      },
      '&.Mui-disabled:hover':{
        cursor: 'not-allowed',
        
      }      
  },          

});

class DeleteButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('DeleteButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Pass" onClick={this._handleClick} disabled={!this.state.data.allow_archive}>
              <DeleteForeverIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}


class ManageButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ManageButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabManage} aria-label="Delete Pass" onClick={this._handleClick}>
              <ArrowForwardIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}

class AccountSubscriptionsManager extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      subscriptions: window.subscriptions || [],
      error: false,
      error_str: '',
      delete_subscription: false,
      security: {name: window.security_name, token: window.security_token},
      loading: false,
      manage_subscription_focus: {},
      locked: false,
      reload: false,
    };

    this.onManageSubscription = this.onManageSubscription.bind(this);
    this.onDeleteSubscription = this.onDeleteSubscription.bind(this);
    this.onCancelCancel = this.onCancelCancel.bind(this);
    this.onCompleteCancel = this.onCompleteCancel.bind(this);
    
    this.onLock  = this.onLock.bind(this);
    this.interpretState  = this.interpretState.bind(this);
  }
  onLock( v )
  {
    this.setState({'locked': v});
  }
  onManageSubscription( obj )
  {
    window.location.href="/account/subscription/" + obj.id;
  }

  onDeleteSubscription( obj )
  {
    this.setState({'manage_subscription_focus': obj, 'delete_subscription': true});
  }
  onCancelCancel( obj )
  {
    this.setState({'manage_subscription_focus': null, 'delete_subscription': false});
  }
  onCompleteCancel( obj )
  {
      console.log(this.state.delete_focus);
      var chain = '&id=' + this.state.manage_subscription_focus.id;
      var _this = this;

      try{
            fetch("/account/do_ajax_cancel_subscription", {
              method: "POST",
              body: this.state.security.name + '=' + this.state.security.token + chain,
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "X-Requested-With": 'XMLHttpRequest',
                  "X_REQUESTED_WITH": 'xmlhttprequest'
              }
          })
          .then(res => {
              return res.json();
          })
          .then(response => {
              //console.log(data);
              if(response.status === 'OK')
              {
                _this.setState({'delete_subscription': false});
                window.location.reload();
              }
              else
              {
                  _this.setState({'delete_subscription': false, 'error': true, 'error_str': response.reason});
              }
          }).catch(function (err) {
            _this.setState({'delete_subscription': false, 'error': true, 'error_str': 'Network error. Please try again later. ' + err});
          }); 
      }
      catch(e)
      {
        _this.setState({'delete_subscription': false, 'error': true, 'error_str': 'Network error. Please try again later. ' + e});
      }
    
  }
  

  interpretState( s)
  {

      switch(s)
      {
        case SUBSCRIPTION_MANDATE_STATUS_ERROR:
        {
          return 'Error';
        }
        case SUBSCRIPTION_MANDATE_STATUS_MANUAL:
        case SUBSCRIPTION_MANDATE_STATUS_ACTIVE:
        {
          return 'Active';
        }
        case SUBSCRIPTION_MANDATE_STATUS_PAUSED:
        {
          return 'Paused';
        }
        case SUBSCRIPTION_MANDATE_STATUS_DELETED:
        {
          return 'Cancelled';
        }
        case SUBSCRIPTION_MANDATE_STATUS_SUSPENDED:
        {
          return 'Suspended';
        }
        
        default:  
        {
          return 'unknown';
        }
        
      }

  }
  interpretParentType( s, v )
  {
      switch(s)
      {
        case INVOICE_PARENT_TYPE_SUBSCRIPTION:
        {
          return 'Pass'
          //return x;
        }        
        default:  
        {
          return 'unknown';
        }
        
      }

  }


  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>
        <AppBar position="sticky" className="appBar">
            <Toolbar>
              <Typography variant="h2" className={classes.title}>
                  Subscriptions manager
              </Typography>
            </Toolbar>
        </AppBar>


        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
              <Grid item xs={12}>
                {this.state.error === true && 
                  (<div>
                    <Alert severity="error">{this.state.error_str}</Alert>
                  </div>)
                }
              </Grid>
              <Grid item xs={12}>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Name 
                        </TableCell>  
                        <TableCell className={classes.cell}>
                          Next payment
                        </TableCell>         
                        <TableCell className={classes.cellCenter}>
                          Status
                        </TableCell>  
                        <TableCell className={classes.cellCenter}>
                          Cost
                        </TableCell>           
                        <TableCell className={classes.cellCenter}>
                          Detail
                        </TableCell>                                         
                        <TableCell className={classes.cellCenter}>
                          Delete
                        </TableCell>                                                                                                  
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.loading === false && this.state.subscriptions.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={classes.empty}>No subscriptions.</div> 
                        </TableCell>
                      </TableRow>
                    )}
                    {this.state.loading === false && this.state.subscriptions.filter(function(obj) {
                          return obj.archived === true? false:true;
                    }).map( el => (
                      <TableRow key={uuidv1.v1()}>
                        <TableCell>
                          {el.id}
                        </TableCell>
                        <TableCell>
                          {el.name}
                        </TableCell>  
                        <TableCell>
                          {moment(el.due).format("LL")}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {this.interpretState(el.state)}
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          {el.currency + ' ' + parseFloat(el.gross).toFixed(2)}
                        </TableCell>                                                                          
                        <TableCell className={classes.textCenter}>
                          <ManageButton data={el} classes={classes} callback={this.onManageSubscription}/>
                        </TableCell> 
                        <TableCell className={classes.textCenter}>
                          <DeleteButton data={el} classes={classes} callback={this.onDeleteSubscription}/>
                        </TableCell>                                                                                                            
                      </TableRow>
                    ))}
                    {this.state.loading === true && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Loading />
                        </TableCell>
                      </TableRow>
                    )}                    
                    </TableBody>

                  </Table>
                </TableContainer>
              </Grid>
          </Grid>
        </div>
        {this.state.locked === true && (
          <div className="overlay">
              <Processing />  
          </div> 
        )}
        {this.state.delete_subscription === true && (
            <CoreDialog title="Cancel subscription" body="You are about to cancel this subscriptions. This will cancel all associated future services that have not already been paid for. Note: Any payments that are currently pending may still be processed. Are you sure you want to continue?" state={true} danger={true} onCancel={this.onCancelCancel} actions={[{label:"Complete cancellation", action:this.onCompleteCancel}]}  />          
        )}        


      </div> 

    );
  }
}

export default withStyles(styles)(AccountSubscriptionsManager);
