import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';


import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CachedIcon from '@material-ui/icons/Cached';
import {loadStripe} from '@stripe/stripe-js';
import parse from 'html-react-parser';
import Avatar from '@material-ui/core/Avatar';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { green, red } from '@material-ui/core/colors';
import SelectCard from '../subscriptions/SelectCard';
import Alert from '@material-ui/lab/Alert';

import {
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 350,
  },
  fullList: {
    width: 'auto',
    maxWidth: 350,
  },
  wrap:{
    padding:'10px 20px',
    maxWidth: 650,
    width: 'auto',

  },
  h2Title:{
    color: red[500],
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper:{
    backgroundColor: 'inherit'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  }, 
  error:{
    color: red[500],
  },  
  avatarSuccess: {
    color: '#fff',
    width: '100px',
    height: '100px',
    backgroundColor: green[500],
    '& .ico':{
      width: '50px',
      height: '50px',
    }
  },
  icoWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'  
  },
  actionIco:{
    width: '100px',
    height: '100px',

  }   
}));

function getSteps() {
  return ['Select resolution', 'Complete   fix', 'Problem resolved'];
}

function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(props.step);
  const steps = getSteps();


  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

function StripeProcessor(props) {
  const stripe =  useStripe();
  const resolution = props.resolution;
  const processing = props.processing;
  const classes = props.classes;
  const secret = props.secret;
  const security = props.security;
  const id = props.id;
  const pi = props.pi;
  const pm = props.pm;
  const setError = props.setErrorCallback;
  const setProcessing = props.setProcessingCallback;
  const onDone = props.onDoneCallback;
  const onProcess = () =>{
        try{
            setProcessing( true )
            stripe.confirmCardPayment(secret, {
              payment_method: pm
            }).then(function(result) {
              if (result.error) {
                // Show error to your customer
                setProcessing( false )
                console.log(result.error.message);
                setError(result.error.message);
              } else {
                if (result.paymentIntent.status === 'succeeded') {
                  // The payment is complete - send a message to re-activate the payment - re-check server side. 
                  var chain = '&id=' + id;
                  chain     += '&pi=' + pi;
            
                  try{
                        fetch("/account/ajax_invoice_charge_complete", {
                          method: "POST",
                          body: security.name + '=' + security.token + chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //console.log(data);
                          if(response.status === 'OK')
                          {
                            setProcessing( false )
                            onDone();
                          }
                          else
                          {
                            setProcessing( false )
                            setError('Payment succeeded but we have not been able to update the records. Please contact us with error code: SP01. Detail:' + response.message );
                          }
                      }).catch(function (err) {
                          setError('Payment succeeded but we have not been able to update the records due to a connection error. Please contact us with error code: SP02. Detail:' + err );
                          setProcessing( false )
                        }); 
                  }
                  catch(e)
                  {
                      setError('Payment succeeded but we have not been able to update the records due to a connection error. Please contact us with error code: SP03. Detail:' + e );
                      setProcessing( false )

                  }                  
                }
                else
                {
                  setProcessing( false )
                  setError('Payment error. We have not been able to process the payment. Returned result: ' + result.paymentIntent.status );
                }
              }
            });
        }
        catch(e){
          setProcessing( false )
          console.log('Stripe rejected payment request due to API error: ' +  e);
          setError("Stripe rejected payment request due to API error:&nbsp;" +  e);  
        }
    }

    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={resolution !== 'reattempt' || processing}
        onClick={onProcess}
      >
        Pay now
      </Button>
    );

}

export default function StripePaymentWizardDrawer(props) {
  const stripe_object = loadStripe(window.key, {stripeAccount: window.acct});
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  const reattempt_allowed = props.reattempt_allowed;
  const [step, setStep] = React.useState(0);
  const [resolution, handleResolutionChange] = React.useState(reattempt_allowed?'reattempt':'new');
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState('');
  const pi = props.pi;
  const pm = props.pm;
  const secret = props.secret;
  const id = props.id;
  const security = props.security;
  const onComplete = props.onCompleteCallback;
  const paymentMethods = props.paymentMethods;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const onHandleResolutionChange = (event) =>
  {
    handleResolutionChange(event.target.value);
  }


  const onNext = () => {
    if(step < 3)
    {
      setStep(step+1);
    }
  }

  const onClose = () => {
    onComplete();
  }

  const onDone = () => {
    setStep(step+1);
  }


  const onSubscriptionUpdateComplete = () => {
    setStep(step+1);
  }


  return (
    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              <div className={classes.wrap}>
                  <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                      <Grid item xs={12}>
                        <HorizontalLabelPositionBelowStepper step={step} />
                      </Grid>

                      {step === 0 && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <h2 className={classes.h2Title}>Payment Wizard</h2>
                          </Grid>
                          <Grid item xs={12}>
                            <p>
                              We have attempted to charge your registered credit card, but unfortunately detected a problem. Any associated services have been suspended. 
                              To fix this issues, please select one of the following options and click next to continue.

                            </p>
                            
                          </Grid>
                          <Grid item xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Select a resolution option</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" value={resolution} onChange={onHandleResolutionChange}>
                                  <FormControlLabel value="reattempt" control={<Radio />} disabled={!reattempt_allowed} label="Re-attempt payment" />
                                  <FormControlLabel value="new" control={<Radio />} label="Select an alternative payment method" />
                                </RadioGroup>
                              </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={resolution !== 'reattempt' && resolution !== 'new' }
                                  onClick={onNext}
                                >
                                  Next
                              </Button>                            
                          </Grid>                          
                        </React.Fragment>
                      )}
                      {step === 1 && resolution === 'reattempt' && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <h2 className={classes.h2Title}>Payment Wizard</h2>
                          </Grid>
                          <Grid item xs={12}>
                            <p>
                              Thank you. To continue with the payment, click the 'pay now' button below. Upon clicking the 'pay now' button, a payment request will be made to the issuing bank of your registered credit or debit card. 
                            </p>
                            <p>
                              You may be aked to re-authorise the payment by your bank. If asked to do so, please follow their instructions on screen.
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <div className={classes.message}>
                                <div className={classes.info}>
                                    {processing && error === '' && (
                                      <div>
                                        <div className={classes.icoWrapper}>
                                            <CachedIcon className={classes.actionIco}/><br/>
                                        </div>
                                        <div className={classes.icoWrapper}>
                                            Please wait.
                                        </div>
                                      </div>
                                    )}
                                    {error !== '' && (
                                        <Alert severity="error">{parse('<span class="' + classes.error + '">' + error +'</span>')}</Alert>          
                                    )}

                                </div>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                              <Elements stripe={stripe_object}>
                                <StripeProcessor classes={classes} resolution={resolution} secret={secret} pi={pi} pm={pm} id={id} security={security} setErrorCallback={setError} setProcessingCallback={setProcessing} processing={processing} onDoneCallback={onDone}/>
                              </Elements>

                          </Grid> 

                        </React.Fragment>
                      )}
                      {step === 1 && resolution === 'new' && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <h2 className={classes.h2Title}>Payment Wizard</h2>
                          </Grid>
                          <Grid item xs={12}>
                            <p>
                              Thank you. Please select from one of the following credit or debit cards or click 'add new card', to register a new card.  
                            </p>
                            <p>
                              You may be aked to re-authorise the payment by your bank. If asked to do so, please follow their instructions on screen.
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <div className={classes.message}>
                                <div className={classes.info}>
                                    {processing && error === '' && (
                                      <div>
                                        <div className={classes.icoWrapper}>
                                            <CachedIcon className={classes.actionIco}/><br/>
                                        </div>
                                        <div className={classes.icoWrapper}>
                                            Please wait.
                                        </div>
                                      </div>
                                    )}
                                    {error !== '' && (
                                        <Alert severity="error">{parse('<span class="' + classes.error + '">' + error +'</span>')}</Alert>          
                                    )}

                                </div>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                              <SelectCard 
                                  callback={onSubscriptionUpdateComplete} 
                                  security={security} 
                                  payment_methods={paymentMethods} 
                                  id={id}
                              />
                          </Grid> 

                        </React.Fragment>
                      )}                      
                      {step === 2 && resolution === 'reattempt' && (
                        <React.Fragment>
                          <Grid item xs={12}>
                            <h2 className={classes.h2Title}>Payment Wizard</h2>
                          </Grid>
                          <Grid item xs={12}>
                            <p>
                              Thank you. We have successfully charged your credit or debit card. Your subscription and any associated services have been reactivated. 
                            </p>
                          </Grid>
                          <Grid item xs={12}>
                            <span className={classes.icoWrapper}>
                                <Avatar className={classes.avatarSuccess}>
                                  <ThumbUpIcon className="ico" />
                                </Avatar>
                            </span>
                          </Grid>
                          <Grid item>
                            <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  disabled={resolution !== 'reattempt' && resolution !== 'new' }
                                  onClick={onClose}
                                >
                                  Close
                              </Button>                             
                          </Grid>
                        </React.Fragment>
                      )}
                      {step === 2 && resolution === 'new' && ( 
                        <React.Fragment>
                        <Grid item xs={12}>
                          <h2 className={classes.h2Title}>Payment Wizard</h2>
                        </Grid>
                        <Grid item xs={12}>
                          <p>
                            Thank you. We have successfully updated your subscription. We will attempt to take payment for this invoice shortly.  
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <span className={classes.icoWrapper}>
                              <Avatar className={classes.avatarSuccess}>
                                <ThumbUpIcon className="ico" />
                              </Avatar>
                          </span>
                        </Grid>
                        <Grid item>
                          <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={resolution !== 'reattempt' && resolution !== 'new' }
                                onClick={onClose}
                              >
                                Close
                            </Button>                             
                        </Grid>
                      </React.Fragment>                        
                      )}

                  </Grid>

              </div>

          </Drawer>

        </React.Fragment>
      ))}
    </div>
  );
}